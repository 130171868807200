@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');


// Base
@import "base/_variables.scss";
@import "base/_mixins.scss";
@import "base/_general.scss";
@import "base/_typography.scss";


// Layout
@import "layout/_grid.scss";
@import "layout/_elements.scss";
@import "layout/_layout.scss";
@import "layout/_navigation.scss";
@import "layout/_footer.scss";
@import "layout/_spacings.scss";
@import "layout/_helper-classes.scss";
@import "layout/_wordpress.scss";
@import "layout/_theme-styles.scss";
@import "layout/_stat-cards.scss";

@import "marquee/jquery.text-marquee";

// Custom styles
@import "custom";

