.main-container {
    margin-top: 35px;
}

.logo__img {
    @include bp-xl-up {
        width: 200px;
    }
    @include bp-lg-down {
        width: 200px;
    }
    width: 135px;
}

.logo__img-big {
    width: 250px;
}

.custom-control-label {
    &.light {
        color: #fff;
        a {
            color: $main-color;
            &:hover, &:focus, &:active {
                text-decoration: underline;
            }
        }
    }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $main-color;
    background-color: $main-color;
}

.custom-control-label {
    padding-left: 5px;
}

.iframe-wrapper {
    width: 100%;
    iframe {
        max-width: 100% !important;
    }
}

.sidenav__logo {
    padding-left: 22px;
    padding-bottom: 25px;
    img {
        width: 70%;
    }
}

.no-text-transform {
    text-transform: none !important;
}

.mt-24 {
    margin-top: 24px;
}

.bold {
    font-weight: 700;
}

.new-line {
    display: block !important;
}

.fb-group-img {
    margin-bottom: 2px;
}

.navbar-logo {
    line-height: 48px;
}
.entry-navigation {
    .entry-navigation__label {
        a {
            color: #83858F;
            font-size: 13px;
            font-weight: 400;
        }
    }

}
.stat-card__body-box {
    .data-change {
        sup {
            font-size: 12px;
        }
    }
}
.inline-zone-mobile {
    display: none;
}

@media only screen and (max-device-width: 480px) {
  .inline-zone-desktop {
    display: none;
  }
  .inline-zone-mobile {
    display: block;
  }
}

.unsubscribe-panel {
    padding: 125px 0;
    margin: 24px 0;
    height: auto;
    cursor: auto;
    margin-bottom: 24px !important;
}

.webshop-class {
    background-color: $main-color;
    i {
        padding-right: 5px;
    }
    &:hover, &:focus, &:active {
        color: #fff !important;
    }
}

.sidenav__menu-url {
    i {
        padding-right: 5px;
    }
}