/*-------------------------------------------------------*/
/* Theme Styles
/*-------------------------------------------------------*/

/* Style Default
-------------------------------------------------------*/
.style-default {

  .card,
  .sidebar .widget,
  .content-box {
    box-shadow: 0 3px 2px rgba(#000, .10);
  }

  .sidebar .widget,
  .owl-prev,
  .owl-next {
    border: 0;
  }


}


/* Style Rounded
-------------------------------------------------------*/
.style-rounded { 
  .trending-now,
  .content-box,
  .card,
  .card__body,
  input, select, textarea,
  .social,
  .entry__meta-category--label,
  .owl-prev,
  .owl-next,
  .thumb__img-holder,
  .entry__play-time,
  .pagination__page,
  #back-to-top,
  .btn,
  .sidebar .widget {
    border-radius: 5px;
  }

  .widget-search-button,
  .search-button,
  .mc4wp-form-fields input[type=submit] {
    border-radius: 0 5px 5px 0;
  }
  .mc4wp-form-fields button {
    border-radius: 0 5px 5px 0;
  }

  .mc4wp-form-fields input[type=email],
  .mc4wp-form-fields input[type=text] {
    border-radius: 5px 0 0 5px;
  }

  .nav__search-box {
    border-radius: 0 0 5px 5px;
  }
}


/* Style Politics
-------------------------------------------------------*/
.style-politics {
  font-family: $font-roboto;

  h1,h2,h3,h4,h5,h6 {
    letter-spacing: normal;
    line-height: 1.2;
  }

  .entry__excerpt p {
    font-size: 15px;
    line-height: 22px;
  }

  h1,h2,h3,h4,h5,h6,
  .nav__menu > li > a,
  .entry__meta-category,
  .btn,
  .all-posts-url,
  .sidenav__menu-url,
  .trending-now__label,
  .widget_tag_cloud a,
  .entry__tags a,
  .entry-date-label,
  label {
    font-family: $font-roboto;
  }

  .widget-title,
  .section-title {
    color: #F44336;
  }
  
  .widget-title {
    font-size: 14px;
  }

  .entry__title {
    font-size: 18px;
    margin-top: 8px;
  }

  .sidebar .widget {
    padding: 32px 16px;
  }

  .title-wrap--line {
    border-top: 2px solid #E3E4E8;
  }
}


/* Style Fashion
-------------------------------------------------------*/
.style-fashion {
  font-family: $font-barlow;

  h1,h2,h3,h4,h5,h6 {
    letter-spacing: normal;
    line-height: 1.2;
    font-family: $font-lora;
  }

  p {
    color: $heading-color;
  }

  .nav__menu > li > a,
  .sidenav__menu-url,
  .btn,
  .entry__meta-category,
  .widget_tag_cloud a,
  .entry__tags a,
  .entry-date-label,
  label {
    font-family: $font-barlow;
  }

  .widget-socials .social {
    background-color: #fff;
    color: $heading-color;
  }

  .entry__meta a,
  .entry__meta li {
    color: $heading-color;
  }

  .entry__meta {
    margin-bottom: 12px;
  }

  .entry__meta li {
    font-weight: 500;
  }

  .entry__play-time {
    background-color: rgba(#000, .5);
    border-radius: 5px;
  }

  .widget-title,
  .section-title {
    text-transform: none;
  }

  .widget-title {
    font-size: 20px;
  }

  .title-wrap {
    margin-bottom: 24px;
  }

  .section-title {
    font-size: 24px;
  }  

  .entry__meta-author {
    font-family: $font-lora;
    font-style: italic;
    text-transform: none;
    font-weight: 400;
    font-size: 13px;
    color: $meta-color;
  }

  .entry__title + .entry__meta {
    margin-top: 16px;
  }

  .sidebar .widget {
    background-color: transparent;
    padding: 0;
    border: 0;
  }

  .sidebar .widget_mc4wp_form_widget {
    background-color: #fff;
  }

  .newsletter-wide {
    background-color: #FFEBEE;
  }

  .post-list {
    min-height: 285px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;

    &:last-child {
      border-color: transparent;
    }
  }

  .copyright {
    color: $meta-color;
  }
}


/* Style Games
-------------------------------------------------------*/
.style-games {
  font-family: $font-barlow;

  h1,h2,h3,h4,h5,h6 {
    letter-spacing: normal;
    line-height: 1.2;
    font-family: $font-barlow;
    font-weight: 700;
  }
  
  .btn,
  .nav__menu > li > a,
  .trending-now__label,
  .sidenav__menu-url,
  .entry__meta-category,
  .widget_tag_cloud a,
  .entry__tags a,
  .entry-date-label,
  label {
    font-family: $font-barlow;
  }

  .entry__title {
    font-size: 18px;
  }

  .entry__meta-category {
    font-weight: 700;
    font-size: 14px;
  }

  .card {
    box-shadow: none;
  }

  .card__body {
    border: 1px solid $border-color;
  }

  .footer__widgets {
    padding: 56px 0;
  }

  .footer .widget-title {
    color: $main-color;
    font-size: 14px;
  }

  .footer__nav-menu + .copyright {
    margin-top: 24px;
  }
}


/* Style Videos
-------------------------------------------------------*/
.style-videos {
  font-family: $font-poppins;

  h1,h2,h3,h4,h5,h6 {
    letter-spacing: normal;
    font-family: $font-poppins;
    font-weight: 500;
  }

  .section-title,
  .widget-title {
    font-weight: 700;
  }
  
  .btn,
  .nav__menu > li > a,
  .trending-now__label,
  .sidenav__menu-url,
  .entry__meta-category,
  .widget_tag_cloud a,
  .entry__tags a,
  .entry-date-label,
  label {
    font-family: $font-poppins;
  }

  .nav__dropdown.active {
    background-color: $main-color;

    & > a {
      color: #fff;
    }
  }
}


/* Style Music
-------------------------------------------------------*/
.style-music {
  font-family: $font-roboto;

  h1,h2,h3,h4,h5,h6 {
    letter-spacing: normal;
    font-family: $font-rubik;
    font-weight: 700;
    line-height: 1.2;
  }

  .entry-date-label {
    font-family: $font-rubik;
  }

  p {
    font-family: $font-roboto;
  }

  .btn,
  .nav__menu > li > a,
  .trending-now__label,
  .sidenav__menu-url,
  .entry__meta-category,
  .widget_tag_cloud a,
  .entry__tags a,
  label {
    font-family: $font-roboto;
  }

  .card__body {
    border: 1px solid $border-color;
  }

  .widget-title {
    color: $main-color;
    font-size: 14px;
  }
}