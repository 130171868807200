.stat-card {
  padding-bottom: 24px;
  @include bp-sm-down {
    text-align: center;
  }
  &__body {
    padding: 30px 25px;
    background-color: #fff;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    &-title {
      padding-bottom: 25px;
      h3 {
        font-weight: 700;
        margin-bottom: 0;
        font-size: 16px;
        text-transform: uppercase;
        .first {
          color: $koronavirus-red;
        }
        .second {
          color: $koronavirus-dark;
        }
      }
    }
    .col-xl, .col-lg {
      &:last-child .stat-card__body-box {
        border-right: 0;
      }
      @include bp-md {
        &:nth-child(4n+4) .stat-card__body-box {
          border-right: 0;
        }
      }

      @include bp-sm {
        &:nth-child(2n+3) .stat-card__body-box {
          border-right: 0;
        }
      }

    }
    &-box {
      @include bp-lg-down {
        margin: 15px 0;
      }
      div {
        display: block;
      }
      border-right: 1px solid #dddddd;
      @include bp-sm-down {
        border-right: 0;
      }
      .data-title {
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        &-icon {
          font-size: 15px;
        }
      }
      .data-number {
        font-family: 'Montserrat', sans-serif;
        color: $heading-color;
        font-weight: 700;
        font-size: 32px;
      }
      .data-change {
        color: #171821;
        font-size: 18px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        img {
          width: 14px;
          margin-bottom: 3px;
          margin-right: 5px;
        }
      }
    }
    &.horizontal {
        .details {
          @include bp-sm-up {
          float: right;
          }
          @include bp-sm-down {
            padding-top: 15px;
          }
      }
    }
    &.vertical {
      padding-bottom: 25px;
      h3 {
        margin-bottom: 15px;
      }
      .stat-card__body-title {
        padding-bottom: 0;
      }
      .stat-content {
        &:last-child .stat-card__body-box {
          border-bottom: 0;
          padding-bottom: 0;
        }
        .stat-card__body-box {
          border-right: 0;
          @include bp-lg-up {
          border-bottom: 1px solid #dddddd;
          }
          @include bp-lg-down {
            //border-right: 1px solid #dddddd;
          }
          padding: 20px 0;
        }
      }

    }
  }
  &__footer {
    margin-top: 10px;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;

    &.vertical {
      margin-top: 20px;
      text-align: center;
      p {
        font-size: 13px;
        margin-bottom: 0;
        &.source {
          font-size: 11px;
        }
      }
    }
  }
}