// Config
@import 'base/variables';
@import 'base/mixins';

.text-marquee {
	position: relative;
	display: block;
	overflow: hidden;

	.text-marquee__text {
		white-space: nowrap;
	}

	&.text-marquee--initialized {}
}